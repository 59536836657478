import React, { Component } from 'react';
import Cabecalho from '../componentes/Cabecalho';
import Rodape from '../componentes/Rodape';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';
import { id_firebase } from '../content-site';
import { firestore } from './../firebase-config';

class Contato extends Component {

    constructor(props) {
        super(props);

        this.state = {
            assunto: '',
            nome: '',
            email: '',
            telefone: '',
            mensagem: '',
            enviado: false,
            msg_enviado: '',
            revenda: {},
        }
        this.mounted = false;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount() {

        window.scrollTo(0, 0);

        this.mounted = true;

        firestore.collection("revenda").doc(id_firebase)
            .get()
            .then(doc => {
                if (doc.exists) {
                    this.setState({
                        revenda: doc.data(),
                    });
                }
            }).catch(function (error) {
            });
    }

    handleSubmit(event) {
        
        console.log(this.state.revenda.peemail)
        event.preventDefault();
    
        this.setState({ enviado: true, msg_enviado: 'Enviando mensagem...' });
    
        const mensagem = "Nome: " + this.state.nome + 
                         "<br>E-mail: " + this.state.email + 
                         "<br>Telefone: " + this.state.telefone + 
                         "<br>Veículo: " + this.state.url_veiculo + 
                         "<br>Mensagem: " + this.state.mensagem;
    
        const destinatarios = [this.state.revenda.peemail, 'Lead@Intermedioveiculos.com.br', 'mayaracanever.oliveira@hotmail.com'];
    
        const fetchPromises = destinatarios.map(email => {
            return fetch('https://us-central1-intermedio-1513801146022.cloudfunctions.net/enviarEmail', {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify({
                    remetente: "Contato " + this.state.revenda.penome_fantasia + "<" + this.state.revenda.peemail + ">",
                    assunto: "Interesse no veículo - Site " + this.state.revenda.penome_fantasia,
                    destinatarios: email,
                    corpo: mensagem,
                    corpoHtml: mensagem
                })
            });
        });
    
        Promise.all(fetchPromises)
            .then(() => {
                this.setState({
                    enviado: true, 
                    msg_enviado: 'E-Mail Enviado com Sucesso',
                    nome: "", 
                    email: "", 
                    telefone: "", 
                    mensagem: "",
                });
                setTimeout(() => this.setState({ enviado: false }), 5000);
            })
            .catch(error => {
                this.setState({
                    enviado: true,
                    msg_enviado: 'Erro ao enviar e-mail, tentar novamente mais tarde',
                });
                setTimeout(() => this.setState({ enviado: false }), 5000);
            });
    }
    
    handleHidden() {
        const hiddenOut = document.getElementById("box-whatsapp")
        hiddenOut.classList.remove('hidden')
    }

    verificaFormValido = () => {
        const { nome, telefone } = this.state
        const formValido = nome.trim() !== '' && telefone.trim() !== '';
        this.setState({ formValido });
    }

    handleChange(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        }, this.verificaFormValido);
    }


    render() {
        return (
            <div>
                <Cabecalho revenda={this.state.revenda} />

                <section>
                    <div className="container">
                        <div className="row text-center intro-title">
                            <div className="col-md-12 text-md-left d-inline-block">
                                <h1 className="text-black">CONTATE-NOS</h1>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="contact page-section-ptb white-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-title">
                                    <h2>Informações da Loja Canever do Auto Shopping Central.</h2>
                                    <div className="separator"></div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="contact-box text-center">
                                    <i className="fa fa-map-marker"></i>
                                    <h5>ENDEREÇO</h5>
                                    <p>R. Mal. Deodoro, 2785 - Centro, São Bernardo do Campo - SP, 09710-193</p>
                                </div>
                            </div>
                            {/*<div className="col-lg-3 col-md-6">
                                <div className="contact-box text-center">
                                    <i className="fa fa-phone"></i>
                                    <h5>TELEFONE</h5>
                                    <p>(11) 94553-3788</p>
                                </div>
                            </div>*/}
                            <div className="col-lg-6 col-md-6">
                                <div className="contact-box text-center">
                                    <i className="fa fa-envelope-o"></i>
                                    <h5>Email</h5>
                                    <p>"mayaracanever.oliveira@hotmail.com"</p>
                                </div>
                            </div>
                            {/*<div className="col-lg-3 col-md-6">
                                <div className="contact-box text-center">
                                    <i className="fa fa-whatsapp"></i>
                                    <p>(11) 94553-3788</p>
                                    <p>-</p>
                                </div>
                            </div>*/}
                        </div>

                        <div className="contatos-shop">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-title">
                                    <h2>Informações da Loja Canever do Auto Shopping Imigrantes.</h2>
                                    <div className="separator"></div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="contact-box text-center">
                                    <i className="fa fa-map-marker"></i>
                                    <h5>ENDEREÇO</h5>
                                    <p>Str. das Lágrimas, 20 - Rudge Ramos, São Bernardo do Campo- SP, CEP: 09642-000</p>
                                </div>
                            </div>
                            {/*<div className="col-lg-3 col-md-6">
                                <div className="contact-box text-center">
                                    <i className="fa fa-phone"></i>
                                    <h5>TELEFONE</h5>
                                    <p>(11) 94553-3788</p>
                                </div>
                            </div>*/}
                            <div className="col-lg-6 col-md-6">
                                <div className="contact-box text-center">
                                    <i className="fa fa-envelope-o"></i>
                                    <h5>Email</h5>
                                    <p>"ma_canever@hotmail.com"</p>
                                </div>
                            </div>
                            {/*<div className="col-lg-3 col-md-6">
                                <div className="contact-box text-center">
                                    <i className="fa fa-whatsapp"></i>
                                    <p>(11) 94553-3788</p>
                                    <p>-</p>
                                </div>
                            </div>*/}
                        </div>
                        </div>
                        <div className="page-section-ptb">
                            <div className="row">
                                <div className="col-lg-8 col-sm-12">
                                    <div className="gray-form">
                                    <div className="b-detail__main-aside-about wow zoomInUp" data-wow-delay="0.5s">
                                    <h2 className="s-titleDet">ENTRE EM CONTATO!</h2>
                                                {
                                                    this.state.revenda.petelefone2 !== undefined && this.state.revenda.petelefone2.trim().length > 0 ?
                                                        (
                                                            <div id='box-whatsapp' className="b-detail__main-aside-about-call hidden">
                                                                <span className="fa fa-whatsapp"></span>
                                                                <div>
                                                                    <a href={'https://api.whatsapp.com/send?text=Olá, vi um anúncio em seu site e gostaria de saber mais sobre o veículo ?&phone=' + retiraMascara(this.state.revenda.petelefone2, "link")}>{retiraMascara(this.state.revenda.petelefone2)}
                                                                    </a>
                                                                </div>

                                                                <p>Entre em contato pelo Whatsapp ou ligação</p>
                                                            </div>
                                                        ) : <div></div>
                                                }
                                        <div id="formmessage" className="form-notice" style={{ display: "none" }}>A mensagem de sucesso / erro vai aqui</div>
                                        <ValidatorForm id="form-contato" ref="form" onSubmit={this.handleSubmit} >
                                                        <TextValidator
                                                            label="NOME *"
                                                            onChange={this.handleChange}
                                                            name="nome"
                                                            value={this.state.nome}
                                                            className="form-ui-bootstrap"
                                                            validators={['required']}
                                                            errorMessages={['Preenchimento obrigatório']}
                                                        />
                                                        <InputMask
                                                            mask="(99) 99999-9999"
                                                            value={this.state.telefone}
                                                            className="form-ui-bootstrap"
                                                            label="TELEFONE *"
                                                            name="telefone"
                                                            onChange={this.handleChange}
                                                        >
                                                            {() => <TextField
                                                                label="TELEFONE *"
                                                                name="telefone"
                                                                type="text"
                                                                className="form-ui-bootstrap"
                                                            />}
                                                        </InputMask>
                                                        <button type="submit" onClick={this.handleHidden} className="btn m-btn" disabled={!this.state.formValido}>ENVIAR MENSAGEM<span className="fa fa-angle-right"></span></button>
                                                        {
                                                            this.state.enviado &&
                                                            <blockquote>
                                                                <p><em>{this.state.msg_enviado}</em></p>
                                                            </blockquote>
                                                        }
                                                    </ValidatorForm>
                                                    <div/>
                                            </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-12 mt-lg-0 mt-4">
                                    <div className="opening-hours gray-bg mt-sm-0">
                                        <h6>Horário de funcionamento</h6>
                                        <ul className="list-style-none">
                                            <li><strong>Domingo</strong> <span className="text-red"> Fechado</span></li>
                                            <li><strong>Segunda</strong> <span> 08:00 ás 18:00 </span></li>
                                            <li><strong>Terça </strong>  <span> 08:00 ás 18:00 </span></li>
                                            <li><strong>Quarta </strong> <span> 08:00 ás 18:00 </span></li>
                                            <li><strong>Quinta </strong> <span> 08:00 ás 18:00 </span></li>
                                            <li><strong>Sexta </strong>  <span> 08:00 ás 18:00 </span></li>
                                            <li><strong>Sababo </strong> <span> 08:00 ás 12:00 </span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="feature-box-3">
                                    <div className="icon">
                                        <i className="fa fa-clock-o"></i>
                                    </div>
                                    <div className="content">
                                        <h6>HORÁRIO DE FUNCIONAMENTO</h6>
                                        <p>Atendimento ao cliente não é uma técnica a ser implantada, mas uma postura a ser cultivada. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="feature-box-3">
                                    <div className="icon">
                                        <i className="fa fa-support"></i>
                                    </div>
                                    <div className="content">
                                        <h6>NOSSO CENTRO DE SUPORTE</h6>
                                        <p>Atendimento em redes sociais, whatsapp e sites para maior conforto de nosso clientes. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="feature-box-3">
                                    <div className="icon">
                                        <i className="fa fa-info"></i>
                                    </div>
                                    <div className="content">
                                        <h6>ALGUMA INFORMAÇÃO </h6>
                                        <p>Caso tenha dúvidas ou sugestões entre em contato com nossa equipe.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="contact-map">
                    <div className="container-fluid">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1826.338063132727!2d-46.54959502164727!3d-23.723257131358896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce4199619938c9%3A0x9534cc12b22a72d6!2sR.%20Mal.%20Deodoro%2C%202785%20-%20Centro%2C%20S%C3%A3o%20Bernardo%20do%20Campo%20-%20SP%2C%2009710-193!5e0!3m2!1spt-BR!2sbr!4v1727289963342!5m2!1spt-BR!2sbr"></iframe>
                    </div>
                </section>

                <Rodape revenda={this.state.revenda} />

            </div>
        )
    }
}

function retiraMascara(value, type = "texto"){
    var numero = '';
    if(value !== undefined){
        numero = value.replace('_', '');
        if(type === "link"){
            numero = numero.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
        }
    }
    return numero;
}

export default Contato;