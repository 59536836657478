import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import Inicio from './pages/Inicio';
import Estoque from './pages/Estoque';
import Contato from './pages/Contato';
import Veiculos from './pages/Veiculos';
import Financiamento from './pages/Financiamento';
import VendaSeuVeiculo from './pages/VendaSeuVeiculo';
import VeiculosCanever from './revendas/Veiculos_Canever';
import VeiculosCanever_Central from './revendas/Veiculos_Canever_Central';





class App extends Component {
  render(){
    return (
      <Router>
          <Switch>  
              <Route path='/' exact component={Inicio} />  
              <Route path='/veiculos/:idVeiculo' component={Veiculos} />
              <Route path='/veiculo_canever_imigrantes/:idVeiculo' component={VeiculosCanever}/>
              <Route path='/veiculo_canever_central/:idVeiculo' component={VeiculosCanever_Central}/>
              <Route path='/contato' component={Contato} />                  
              <Route path='/financiamento' component={Financiamento} />                  
              <Route path='/estoque' component={Estoque} />                  
              <Route path='/vendaseuveiculo' component={VendaSeuVeiculo} />         
              <Route path='/:idempresa' component={Inicio} />         
          </Switch> 
      </Router>
    );
  }
}

export default App;
