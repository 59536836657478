// alterar arquivo: public/index.html
// checar se existe código do google analytics (apagar ou substituir se for o caso)

// alterar arquivos: .firebaserc (conferir nesse se o default e target apontam para o projeto correto do firebase), firebase.json, package.json e info.txt com o nome do projeto no firebase

// color primary: public/assets/switcher/css/color2.css
// color secondary: public/assets/switcher/css/color2.css: 135

export const id_firebase = "AJaxwEeyRHFT0mJSXszX";  // CANEVER IMIGRANTES


export const ids_firebase = [
    "AJaxwEeyRHFT0mJSXszX", // Canever Imigrantes 0
    "psxC92lHXEjFMhZsYVYy" // Canever Central 1
];

    export const href_revenda = [
        "veiculo_canever_imigrantes",
        "veiculo_canever_central"

    ];
    
    // EMAIL POR ID 
    export const emailsPorId = {
        "AJaxwEeyRHFT0mJSXszX": "ma_canever@hotmail.com",
        "psxC92lHXEjFMhZsYVYy": "mayaracanever.oliveira@hotmail.com",

      };
      
      export function getEmailPorId(idRevenda) {
        return emailsPorId[idRevenda] || "E-mail não disponível";
      }
      