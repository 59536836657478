import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { firestore } from '../firebase-config';
import { ids_firebase, getEmailPorId } from '../content-site';

function retiraMascara(value, type = "texto") {
    var numero = '';
    if (value !== undefined) {
        numero = value.replace('_', '');
        if (type === "link") {
            numero = numero.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
        }
    }
    return numero;
}


class Cabecalho extends Component {

    constructor(props) {
        super(props);
        this.state = {
            emailsRevendas: [],
            enderecosRevendas: []
        };
    }

    componentDidMount() {
        const emailsRevendas = [];
        const enderecosRevendas = [];


        ids_firebase.forEach(id => {

            const email = getEmailPorId(id);
            if (email) {
                emailsRevendas.push(email);
            }


            firestore.collection("revenda").doc(id)
                .get()
                .then(doc => {
                    if (doc.exists) {
                        const revendaData = doc.data();
                        enderecosRevendas.push({
                            endereco: revendaData.peendereco_fiscal,
                            numero: revendaData.peendereco_fiscal_numero,
                            bairro: revendaData.pebairro_fiscal,
                            cidade: revendaData.pecidade,
                            uf: revendaData.peuf,
                            mapa_link: revendaData.mapa_link
                        });
                    }
                })
                .catch(error => {
                    console.error("Erro ao buscar informações da revenda: ", error);
                });
        });


        this.setState({
            emailsRevendas: emailsRevendas,
            enderecosRevendas: enderecosRevendas
        });
    }

    render() {

        const { emailsRevendas, enderecosRevendas } = this.state;
        /*   console.log(this.props.revenda); */

        return (
            <header id="header" className="topbar-dark">
                <div className="topbar">
                    <div className="container">
                        <div className="menutop">
                            <div className="menutop-box">
                                <div className="row" style={{ display: "flex", justifyContent: "center" }}>
                                    {emailsRevendas.length > 0 && (
                                            <ul className="text-sobrenos-info">
                                                {emailsRevendas.map((email, index) => (
                                                    <li key={index}>
                                                        <i className="fa fa-envelope-o" style={{ marginRight: "10px" }}></i>
                                                        <a href={"mailto:" + email}>{email}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                    )}
                                </div>
                            </div>
                            <div className="menutop-box">
                                <div className="topbar-right text-lg-right text-center">
                                    <ul className="list-inline">
                                        <li>
                                            <a href={this.props.revenda.facebook_link} target="_blank" rel="noopener noreferrer"><span className="fa fa-facebook-square"></span></a>
                                        </li>
                                        <li>
                                            <a href={this.props.revenda.instagram_link} target="_blank" rel="noopener noreferrer"><span className="fa fa-instagram" target="_blank"></span></a>
                                        </li>
                                        <li>
                                            <a target="_blank" href={this.props.revenda.mapa_link} className="wow slideInLeft" rel="noopener noreferrer" ><i className="fa fa-map-marker"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="menu">
                    <nav id="menu" className="mega-menu">
                        <section className="menu-list-items">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 top-site">
                                        <ul className="menu-logo">
                                            <li>
                                                <a href="index.html"
                                                ><img
                                                        id="logo_dark_img"
                                                        src="images/logo.png"
                                                        alt="logo"
                                                    />
                                                </a>
                                            </li>
                                        </ul>
                                        <ul className="menu-links">
                                            <li><Link to="/" > Início</Link></li>
                                            <li><Link to="/vendaseuveiculo" > Venda Seu Veículo</Link></li>
                                            <li><Link to="/financiamento" > Financiamento</Link></li>
                                            <li><Link to="/contato" > Contato</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </nav>
                </div>
            </header>

        )
    }

}

export default Cabecalho;